import React, { useState, useEffect, useReducer } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Modal from "react-modal"
import withURLSync from "../../URLSync"
import Seo from "../components/seo"
import LoginModal from "../components/home/modal"
import OrderModal from "../components/OrderModal"
import Buttn from "../components/Button"
import drug from "../images/drugplaceholderImg.png"
import { graphql } from "gatsby"


Modal.setAppElement("#___gatsby")

const Search = props => {

  const isBrowser = typeof window !== "undefined"
  const user = isBrowser ? JSON.parse(window.localStorage.getItem("user")) : {}

  //console.log("search props", props.pageContext.innOfApi)
  const [modalIsOpen, setIsOpen] = useState(false)
  const [dosageFormNameState, setDosageFormNameState] = useState([])
  const [apiStrengthPerDosageState, setApiStrengthPerDosageState] = useState([])
  const [innOfApiState, setInnOfApiState] = useState([])
  const [selecteditems, setSelecteditems] = useState([]);
  // const [isSelected, setIsSelected] = useState(false);


  const items = props.data?.similarProductsPage?.edges.map(item => item.node)
  const itemsDoseUniq = [...new Set(items.map(p => p.dosageFormName))]
  const itemsDoseUniqSel = [...new Set(selecteditems.map(p => p.dosageFormName))]
  const itemsStrengthUniq = [...new Set(items.map(p => p.apiStrengthPerDosage))]
  const itemsStrengthUniqSel = [...new Set(selecteditems.map(p => p.apiStrengthPerDosage))]
  const itemsApiUniq = [...new Set(items.map(p => p.innOfApi))]
  const itemsApiUniqSel = [...new Set(selecteditems.map(p => p.innOfApi))]

  const [isOrderModalOpen, setIsOpenOrder] = useState(false)
  const [isChecked, setIsChecked] = useState(undefined)

  const FilteredDoseForm = (items) => {
    return dosageFormNameState.length > 0 ? items.filter(item => dosageFormNameState.indexOf(item.dosageFormName) > -1) : items
  }

  const FilteredStrength = (items) => {
    return apiStrengthPerDosageState.length > 0 ? items.filter(item => apiStrengthPerDosageState.indexOf(item.apiStrengthPerDosage) > -1) : items
  }

  const FilteredApi = (items) => {
    return innOfApiState.length > 0 ? items.filter(item => innOfApiState.indexOf(item.innOfApi) > -1) : items
  }

  const handleSelectForm = (e, item) => {
    //setIsSelected(!isSelected)
    if (e.target.checked) {
      dosageFormNameState.push(item)
    } else {
      let index = dosageFormNameState.indexOf(item)
      dosageFormNameState.splice(index, 1)
    }
    // if (isSelected) {
    //   dosageFormNameState.push(item)
    // } else {
    //   let index = dosageFormNameState.indexOf(item)
    //   dosageFormNameState.splice(index, 1)
    // }
    //console.log("dosageform", dosageFormNameState)
    //console.log("dosageformEq", dosageFormNameState.length > 0 ? items.filter(item => dosageFormNameState.indexOf(item.dosageFormName) > -1) : items)
    const filteredDosageForm = FilteredDoseForm(items)
    setSelecteditems(filteredDosageForm)
    //console.log("selecteditems", selecteditems)
  }

  const handleSelectStrength = (e, item) => {
    if (e.target.checked) {
      apiStrengthPerDosageState.push(item)
    } else {
      let index = apiStrengthPerDosageState.indexOf(item)
      apiStrengthPerDosageState.splice(index, 1)
    }
    //console.log("apiStrengthPerDosageState", apiStrengthPerDosageState)
    //console.log("apiStrengthPerDosageStateEq", apiStrengthPerDosageState.length > 0 ? items.filter(item => apiStrengthPerDosageState.indexOf(item.apiStrengthPerDosage) > -1) : items)
    const filteredDosageForm = FilteredDoseForm(items)
    const filteredDosageFormStrength = FilteredStrength(filteredDosageForm)
    setSelecteditems(filteredDosageFormStrength)
    //console.log("selecteditems", selecteditems)
  }

  const handleSelectApi = (e, item) => {
    if (e.target.checked) {
      innOfApiState.push(item)
    } else {
      let index = innOfApiState.indexOf(item)
      innOfApiState.splice(index, 1)
    }
    //console.log("innOfApiState", innOfApiState)
    //console.log("innOfApiStateEq", innOfApiState.length > 0 ? items.filter(item => innOfApiState.indexOf(item.innOfApi) > -1) : items)
    const filteredDosageForm = FilteredDoseForm(items)
    const filteredDosageFormStrength = FilteredStrength(filteredDosageForm)
    const filteredDosageFormStrengthApi = FilteredApi(filteredDosageFormStrength)
    setSelecteditems(filteredDosageFormStrengthApi)
    //console.log("selecteditems", selecteditems)
  }

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = e => {
    setIsOpen(false)
  }
  // const openModalOrder = () => {
  //   setIsOpenOrder(true)
  // }

  // const closeModalOrder = e => {
  //   setIsOpenOrder(false)
  // }

  const UncheckAll = (check) => {
    //console.log("check", check)
    let i
    for (i = 0; i < check.length; i++)
      check[i].checked = false;
    setDosageFormNameState([])
    setApiStrengthPerDosageState([])
    setInnOfApiState([])
  }



  const keyword = props.uri.replace(/\/search\//g, "").toLowerCase()
    ? props.uri.replace(/\/search\//g, "").toLowerCase() +
    " brands prices in Kenya - Patameds"
    : "medication brands prices in Kenya - Patameds"
  const keyword2 = props.uri.replace(/\/search\//g, "").toLowerCase()
    ? props.uri.replace(/\/search\//g, "").toLowerCase() + " in Kenya - price, brand names, uses, "
    : "medication in Kenya - price, brand names, uses, "
  const keyword3 = props.uri.replace(/\/search\//g, "").toLowerCase()
    ? props.uri.replace(/\/search\//g, "").toLowerCase() + " price in Kenya, "
    : "medication price in Kenya, "
  const keyword4 = props.uri.replace(/\/search\//g, "").toLowerCase() || "medication"
  const keyword5 = props.uri.replace(/\/search\//g, "").toLowerCase()
    ? props.uri.replace(/\/search\//g, "").toLowerCase() +
    " tanzania,somalia,uganda,rwanda,zambia,congo,ethiopia,burundi,nigeria,ghana,egypt,senegal,botswana,togo,south africa,malawi, congo - kinshasa,cameroon,zimbabwe,congo - brazzaville,benin,angola,djibouti,mali,namibia, "
    : "medication tanzania,somalia,uganda,rwanda,zambia,congo,ethiopia,burundi,nigeria,ghana,egypt,senegal,botswana,togo,south africa,malawi, congo - kinshasa,cameroon,zimbabwe,congo - brazzaville,benin,angola,djibouti,mali,namibia, "
  const keyword_desc = props.uri.replace(/\/search\//g, "").toLowerCase()
    ? props.uri.replace(/\/search\//g, "").toLowerCase() +
    " brands e-detailing. Generic name, formulation, strength, manufacturer, prices. Request a quote, place an order, and compare prices from suppliers"
    : "medication brands in Kenya with their distributors. Discover, Negotiate, Order, Procure, Pay"
  const siteurl = "https://www.patameds.com/search/" + props.uri.replace(/\/search\//g, "").toLowerCase()

  return (
    <Layout>
      <Seo
        title={keyword}
        description={keyword_desc}
        keywords={keyword2 + keyword3 + keyword5 + keyword4}
        img="https://www.patameds.com/static/logo-da9b52b652992ee5cb44de5673062d5e.svg"
        siteUrl={siteurl}
        canonical={siteurl}
      ></Seo>
      <Container>
        <LoginModal />
        <Btn onClick={openModal}>Filter</Btn>
        <Products>
          <FilterWrapper>
            <div style={{ fontSize: "20px", margin: "1rem", background: "green", borderRadius: "10px", textAlign: "center", color: "white" }}>Filters</div>
            <div style={{ fontSize: "20px", margin: "1rem", cursor: "pointer", background: "red", borderRadius: "10px", textAlign: "center", color: "white" }}
              onClick={() => {
                isBrowser && UncheckAll(window.document.getElementsByName("filter"))
                setSelecteditems(items)
              }} >Clear Filters</div>
            <FilterBox>
              <p>Formulation</p>
              {apiStrengthPerDosageState.length || innOfApiState.length > 0 ? itemsDoseUniqSel.map((item, index) => {
                const isSelected = dosageFormNameState.length > 0 ? dosageFormNameState.includes(item) : false
                return (<span key={index}>
                  <input
                    type="checkbox"
                    name="filter"
                    checked={isSelected}
                    onChange={(e) => handleSelectForm(e, item)}
                    style={{ marginRight: "1em" }}
                  />
                  {item}
                </span>
                )
              })
                : itemsDoseUniq.map((item, index) => {
                  const isSelected = dosageFormNameState.length > 0 ? dosageFormNameState.includes(item) : false
                  return (<span key={index}>
                    <input
                      type="checkbox"
                      name="filter"
                      checked={isSelected}
                      onChange={(e) => handleSelectForm(e, item)}
                      style={{ marginRight: "1em" }}
                    />
                    {item}
                  </span>
                  )
                })
              }
            </FilterBox>
            <FilterBox>
              <p>Dosage Strength</p>
              {dosageFormNameState.length || innOfApiState.length > 0 ? itemsStrengthUniqSel.map((item, index) => {
                const isSelected = apiStrengthPerDosageState.length > 0 ? apiStrengthPerDosageState.includes(item) : false
                return (
                  <span key={index}>
                    <input
                      type="checkbox"
                      name="filter"
                      checked={isSelected}
                      onChange={(e) => handleSelectStrength(e, item)}
                      style={{ marginRight: "1em" }}
                    />
                    {item}
                  </span>
                )
              })
                : itemsStrengthUniq.map((item, index) => {
                  const isSelected = apiStrengthPerDosageState.length > 0 ? apiStrengthPerDosageState.includes(item) : false
                  return (
                    <span key={index}>
                      <input
                        type="checkbox"
                        name="filter"
                        checked={isSelected}
                        onChange={(e) => handleSelectStrength(e, item)}
                        style={{ marginRight: "1em" }}
                      />
                      {item}
                    </span>
                  )
                })
              }
            </FilterBox>
            <FilterBox>
              <p>Active ingredient</p>
              {dosageFormNameState.length || apiStrengthPerDosageState.length > 0 ? itemsApiUniqSel.map((item, index) => {
                const isSelected = innOfApiState.length > 0 ? innOfApiState.includes(item) : false
                return (
                  <span key={index}>
                    <input
                      type="checkbox"
                      name="filter"
                      checked={isSelected}
                      onChange={(e) => handleSelectApi(e, item)}
                      style={{ marginRight: "1em" }}
                    />
                    {item}
                  </span>
                )
              })
                : itemsApiUniq.map((item, index) => {
                  const isSelected = innOfApiState.length > 0 ? innOfApiState.includes(item) : false
                  return (
                    <span key={index}>
                      <input
                        type="checkbox"
                        name="filter"
                        checked={isSelected}
                        onChange={(e) => handleSelectApi(e, item)}
                        style={{ marginRight: "1em" }}
                      />
                      {item}
                    </span>
                  )
                })
              }
            </FilterBox>
          </FilterWrapper>
          <Wrapper>
            <ProductHeader>Products</ProductHeader>
            <div style={{ overflowWrap: "break-word", textAlign: "center" }}><ProductHeader>{props.location.search.replace(/\?query=/g, "").replace(/\+/g, " ").toUpperCase()}</ProductHeader></div>
            <ProductCards>
              <div>
                {selecteditems.length > 0 ? selecteditems.map((item, index) => {
                  return (
                    <PCard key={index}>
                      <OrderModal
                        distributor={item.distributor}
                        //wholesalers={wholesalers}
                        drugId={item?.id}
                        open={isOrderModalOpen}
                        tab="order"
                      //close={closeModalOrder}
                      />
                      <div>
                        <PLink
                          to={`/product/${item?.productTradeName
                            .replace(/ /g, "+")
                            .toLowerCase()}`}
                        //onClick={() => datalayer(item)}
                        >
                          <ProductInfo2>
                            <div >
                              <img
                                css={`
                            width: 3rem;
                            height: 3rem;
                            `}
                                src={drug}
                                alt={item.productTradeName}
                                style={{ margin: "auto" }}
                              />
                              <p style={{ marginBTop: "0.5rem", fontWeight: "bold" }}>
                                {item.productTradeName?.toUpperCase()}
                              </p>
                            </div>
                            <div>
                              <p style={{ marginBottom: "0.5rem" }}>{item.innOfApi}</p>
                              <p style={{ marginBottom: "0.5rem" }}>
                                {item.apiStrengthPerDosage}
                              </p>
                              <p style={{ marginBottom: "0.5rem" }}>{item.dosageFormName}</p>
                              {user?.admin &&
                                <p style={{ marginBottom: "0.5rem" }}>
                                  {item.distributor?.distributor.name}
                                </p>
                              }
                            </div>
                          </ProductInfo2>
                        </PLink>
                      </div>
                      {/* <ButtonDiv
                        style={{
                          display: Object.entries(items).length >= 0 ? "flex" : "none",
                        }}
                      >
                        <ButtonGreen onClick={e => openModalOrder(e)}>Order</ButtonGreen>

                        <Buttons>
                          {Buttn(
                            "Show Contact",
                            item.distributor?.distributor?.handler?.phoneNumber || "phone"
                          )}
                        </Buttons>
                      </ButtonDiv> */}
                    </PCard>
                  )
                })
                  : items.map((item, index) => {
                    return (
                      <PCard key={index}>
                        <OrderModal
                          distributor={item.distributor}
                          // wholesalers={wholesalers || ["wholesaler"]}
                          drugId={item?.id}
                          open={isOrderModalOpen}
                          tab="order"
                        //close={closeModalOrder}
                        />

                        <div>
                          <PLink
                            to={`/product/${item.productTradeName
                              .replace(/ /g, "+")
                              .toLowerCase()}`}
                          // onClick={() => datalayer(item)}
                          >
                            <ProductInfo2>
                              <div style={{ minWidth: "100px" }}>
                                <img
                                  css={`
                            width: 3rem;
                            height: 3rem;
                            `}
                                  src={drug}
                                  alt={item.productTradeName}
                                  style={{ margin: "auto" }}
                                />
                                <p style={{ marginTop: "0.5rem", fontWeight: "bold" }}>
                                  {item.productTradeName?.toUpperCase()}
                                </p>
                              </div>
                              <div>
                                <p style={{ marginBottom: "0.5rem" }}>{item.innOfApi}</p>
                                <p style={{ marginBottom: "0.5rem" }}>
                                  {item.apiStrengthPerDosage}
                                </p>
                                <p style={{ marginBottom: "0.5rem" }}>{item.dosageFormName}</p>
                                {user?.admin && <p style={{ marginBottom: "0.5rem" }}>
                                  {item.distributor?.distributor.name}
                                </p>}
                              </div>
                            </ProductInfo2>
                          </PLink>
                        </div>
                        {/* <ButtonDiv
                          style={{
                            display: Object.entries(items).length >= 0 ? "flex" : "none",
                          }}
                        >
                          <ButtonGreen onClick={e => openModalOrder(e)}>Order</ButtonGreen>

                          <Buttons>
                            {Buttn(
                              "Show Contact",
                              item.distributor?.distributor?.handler?.phoneNumber || "phone"
                            )}
                          </Buttons>
                        </ButtonDiv> */}
                      </PCard>
                    )
                  })
                }
              </div>
            </ProductCards>
          </Wrapper>
        </Products>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Refinements"
          css={`
              width: 80%;
              margin-right: auto;
              margin-left: auto;
              margin-top: 10rem;
              margin-bottom: 2rem;
              background: #e4e4e4;
              border: 3px solid black;
              padding: 1rem 1rem;
              border-radius: 10px;
              overflow-y: scroll;
              height: -webkit-fill-available;

              @media screen and (min-width: 600px) {
                width: 70%;
              }

              @media screen and (min-width: 1024px) {
                display: none;
              }
            `}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Btn2 onClick={closeModal}>Close</Btn2>
            <Btn2 style={{ background: "red" }} onClick={() => {
              isBrowser && UncheckAll(window.document.getElementsByName("filter"))
              setSelecteditems(items)
            }}>Clear</Btn2>
          </div>
          <FilterWrapperM>
            <FilterBoxM>
              <p>Formulation</p>
              {apiStrengthPerDosageState.length || innOfApiState.length > 0 ? itemsDoseUniqSel.map((item, index) => {
                const isSelected = dosageFormNameState.length > 0 ? dosageFormNameState.includes(item) : false
                return (<span key={index}>
                  <input
                    type="checkbox"
                    name="filter"
                    checked={isSelected}
                    onChange={(e) => handleSelectForm(e, item)}
                    style={{ marginRight: "1em" }}
                  />
                  {item}
                </span>
                )
              })
                : itemsDoseUniq.map((item, index) => {
                  const isSelected = dosageFormNameState.length > 0 ? dosageFormNameState.includes(item) : false
                  return (<span key={index}>
                    <input
                      type="checkbox"
                      name="filter"
                      checked={isSelected}
                      onChange={(e) => handleSelectForm(e, item)}
                      style={{ marginRight: "1em" }}
                    />
                    {item}
                  </span>
                  )
                })
              }
            </FilterBoxM>
            <FilterBoxM>
              <p>Dosage Strength</p>
              {dosageFormNameState.length || innOfApiState.length > 0 ? itemsStrengthUniqSel.map((item, index) => {
                const isSelected = apiStrengthPerDosageState.length > 0 ? apiStrengthPerDosageState.includes(item) : false
                return (
                  <span key={index}>
                    <input
                      type="checkbox"
                      name="filter"
                      checked={isSelected}
                      onChange={(e) => handleSelectStrength(e, item)}
                      style={{ marginRight: "1em" }}
                    />
                    {item}
                  </span>
                )
              })
                : itemsStrengthUniq.map((item, index) => {
                  const isSelected = apiStrengthPerDosageState.length > 0 ? apiStrengthPerDosageState.includes(item) : false
                  return (
                    <span key={index}>
                      <input
                        type="checkbox"
                        name="filter"
                        checked={isSelected}
                        onChange={(e) => handleSelectStrength(e, item)}
                        style={{ marginRight: "1em" }}
                      />
                      {item}
                    </span>
                  )
                })
              }
            </FilterBoxM>
            <FilterBoxM>
              <p>Active ingredient</p>
              {dosageFormNameState.length || apiStrengthPerDosageState.length > 0 ? itemsApiUniqSel.map((item, index) => {
                const isSelected = innOfApiState.length > 0 ? innOfApiState.includes(item) : false
                return (
                  <span key={index}>
                    <input
                      type="checkbox"
                      name="filter"
                      checked={isSelected}
                      onChange={(e) => handleSelectApi(e, item)}
                      style={{ marginRight: "1em" }}
                    />
                    {item}
                  </span>
                )
              })
                : itemsApiUniq.map((item, index) => {
                  const isSelected = innOfApiState.length > 0 ? innOfApiState.includes(item) : false
                  return (
                    <span key={index}>
                      <input
                        type="checkbox"
                        name="filter"
                        checked={isSelected}
                        onChange={(e) => handleSelectApi(e, item)}
                        style={{ marginRight: "1em" }}
                      />
                      {item}
                    </span>
                  )
                })
              }
            </FilterBoxM>
          </FilterWrapperM>
        </Modal>

      </Container>
    </Layout >
  )
}

export default Search

export const query = graphql`
  query($innOfApi: String!) {
    similarProductsPage: allDrugs(filter: {innOfApi: {regex: $innOfApi}}) {
      edges {
        node {
          id
          apiStrengthPerDosage
          atcCodeName
          dosageFormName
          innOfApi
          mahCompanyAddress
          mahCompanyEmail
          mahCompanyName
          pharmacotherapeuticGroup
          productTradeName
          productVisualDesc
          retentionNo
          retentionYear
          routesName
          shelfLifeName
          siteAddress
          siteName
          type
          category
          distributor {
            distributor {
              id
              name
              handler {
                email
                firstName
                lastName
                phoneNumber
              }
            }
            price {
              previous
              current
              vat
              discount
            }
            packSize
            minQuantity
            showPrice
          }
          wholesalers {
            wholesaler {
              id
              name
              locations
              handler {
                email
                firstName
                lastName
                phoneNumber
              }
            }
            price {
              previous
              current
              vat
              discount
            }
            minQuantity
            packSize
            showPrice
            showWholesaler
          }
        }
      }
    }
  }
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #e4e4e4;
`
const Btn = styled.button`
  background: green;
  width: 90px;
  height: 30px;
  position: fixed;
  bottom: 0.25rem;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  font-size: 1.5rem;
  z-index: 1000;
  padding: 1rem 1rem;
  border-radius: 10px;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`
const Btn2 = styled.button`
  background: green;
  width: 90px;
  height: 30px;
  //position: fixed;
  bottom: 1rem;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  font-size: 1.5rem;
  //z-index: 1000;
  padding: 1rem 1rem;
  border-radius: 10px;
`

const Products = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-around;
  background: #e4e4e4;
`

const ProductHeader = styled.h1`
  text-align: center;
  font-size: clamp(1.3rem, 3vw, 2rem);
`

const ProductCards = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0rem 0.5rem;

  @media screen and (min-width: 700px) {
    // align-items: center;
    padding: 0rem 5rem;
  }
`
const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #e4e4e4;
  width: 100%;
`
const FilterWrapper = styled.div`
  height: 100%;
  display: none;
  flex-direction: column;
  //background: #03989e;
  margin-top: 100px;
  margin-left: 100px;
  width: 30%;
  list-style: none;
  //color: #fff;

  @media screen and (min-width: 1024px) {
    display: flex;
  }
`
const FilterBox = styled.div`
  max-height: 400px;
  height:100%;
  overflow: auto;
   color: #000;

  @media screen and (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    border: 1px solid #000;
    border-radius: 10px;
    padding: 1rem;
  }
`
const FilterWrapperM = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  //background: #03989e;
  margin-top: 10px;
  //margin-left: 100px;
  width: 100%;
  list-style: none;
  //color: #fff;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`
const FilterBoxM = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    border: 1px solid #000;
    border-radius: 10px;
    padding: 1rem;
   color: #000;

  @media screen and (min-width: 1024px) {
    display: none;
   
  }
`

const PCard = styled.div`
  width: 100%;
  border: 3px solid #03989e;
  border-radius: 10px;
  height: 100%;
  background: white;
  margin: 0.5rem 0rem;
  padding: 0.5rem 0.5rem;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: 600px) {
    width: 100%;
  }

  @media screen and (min-width: 1100px) {
    width: 35rem;
  }

  @media screen and (min-width: 1350px) {
    width: 40rem;
  }
`

const PLink = styled(Link)`
  :hover {
    text-decoration: none;
  }

  @media screen and (min-width: 600px) {
    flex-direction: row;
    justify-content: space-around;
  }
`

const ProductInfo2 = styled.div`
  display: flex;
  text-align: center;
  margin: 0.25rem;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction:column;
  color: #000;

  @media screen and (min-width: 400px) {
    justify-content: space-between;
    flex-direction:row;
    width: 100%;

    div {
      width: 50%;
      flex-wrap: wrap;
    }
  }
`

const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`
const Buttons = styled.div`
  width: 50%;
  height: 35px;
  margin-left: 1px;
`
const ButtonGreen = styled.div`
  background: #03989e;
  border-radius: 0.5rem;
  color: #fff;
  text-align: center;
  width: 50%;
  height: 35px;
  padding-top: 0.1rem;
  cursor: pointer;
`
